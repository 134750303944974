input.nxt-text-input {
  width: 30%;
  padding: 5px;
  min-height: 15px;
  font-size: 15px;
  line-height: 1;
  color: #666666;
  background: #f4f4f4;
  margin-left: 40px;
  border: 0 none;
  border-bottom: 1px solid;

  &.no-margin {
    margin: 0;
  }
}

input.inline {
  padding: 5px 8px;
  font-size: 15px;
  display: inline;
}

.slider {
  margin: 2rem 0;
  width: 100%;
  max-width: 450px;
  padding: 5px 35px;

  .ticks {
    display: flex;
    justify-content: space-between;

    .tick {
      position: relative;
      display: flex;
      justify-content: center;
      width: 2px;
      background: $color-secondary-300;
      height: 6px;
      line-height: 20px;
      margin-bottom: 5px;
    }
  }

  .input-range {
    &__track {
      background-color: $color-secondary-300;
      border-color: $color-secondary-300;
      height: 0.6rem;
    }

    &__slider {
      background-color: $color-primary-aqua;
      border-color: $color-primary-aqua;
      height: 1.3rem;
      width: 1.3rem;
      margin-top: -0.9rem;
      margin-left: -0.65rem;
    }

    &__track--active {
      background-color: $color-primary-aqua;
      border-color: $color-primary-aqua;
    }

    &__label {
      bottom: -2rem;
      color: $color-primary-navy; /*$color-secondary-500;*/
      font-size: 1.4rem;
      font-family: inherit;

      &--value {
        display: none;
      }
    }

    &__label-container {
      user-select: none;
    }
  }

  &--clean {
    .input-range__slider {
      background-color: transparent;
      border-color: transparent;
    }

    .rc-slider-handle {
      color: $color-secondary-200 !important;
      border-color: $color-secondary-200 !important
    }

    .rc-slider-dot-active {
      color: $color-secondary-200 !important;
      border-color: $color-secondary-200 !important
    }
  }
}

textarea {
  box-sizing: border-box;
  padding: 16px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;

  &.nxt-textarea {
    width: 100%;
    min-height: 80px;
    border: 0px;
    border-bottom: 1px solid $color-secondary-500;
    color: $color-secondary-500;
    background: $color-secondary-100;
  }

  &.nxt-textarea-list {
    width: 50%;
    min-height: 180px;
    border: 1px solid #666666;
    color: #92799a;
    background-color: #ffffff;
  }
}

.select-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;

  .select-item {
    width: 50%;
    margin-bottom: 10px;

    .question,
    input,
    textarea,
    select {
      margin-left: 10px;
    }
  }
}

.time-selector {
  display: inline-block;
  margin-left: 10px;
}

.btn-time-selector {
  margin-left: 10px;
  font-size: 1.4rem;
  font-family: "BentonSansBBVA-Book", sans-serif;
  color: $color-secondary-500;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  box-sizing: border-box;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
}

.calendar {
  margin-left: 10px;
  padding: 20px;
  border-radius: 0.5em;
  border: 1px solid #aaa;
  background-color: #fff;
  color: #303030;
  /*margin: 30px 0 20px 40px;*/
  display: inline-block;
  // display: flex;
  // flex-direction: column;
  // flex-basis: 310px;
  max-width: 310px;

  & .calendar-month_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    & .calendar-header_button {
      cursor: pointer;
      background: transparent;
      border-radius: 50%;
      border: 1px solid #e4e7ea;
      transition: border 0.1s ease-in, color 0.1s ease-in;
      position: relative;
      height: 35px;
      width: 35px;
      margin-left: 0;

      &.is-disabled {
        cursor: default;
      }

      &.is-next:before,
      &.is-prev:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: fill 0.1s ease-in;
        color: #303030;
      }

      &.is-prev:before {
        content: "<";
      }

      &.is-next:after {
        content: ">";
      }
    }

    & .calendar-month_header_title {
      font-size: 20px;
    }
  }

  & .calendar-days_of_week {
    font-size: 13px;
    display: flex;
    color: #c3c3c3;
    text-align: center;
    margin-bottom: 25px;

    & .calendar-days_of_week_day {
      flex-basis: 15%;
    }
  }

  & .calendar-week {
    display: flex;
    margin-bottom: 8px;

    & .calendar-week-day {
      height: 34px;
      width: 34px;
      vertical-align: middle;
      font-size: 15px;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 15%;
      background: #fff;
      position: relative;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      border: none;
      outline: 0;

      &.is-today {
        font-weight: bold;
        font-size: 1.4em;
        color: $color-primary-medium-blue;
      }

      &.is-selectable {
        cursor: pointer;
        position: relative;

        &:before {
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      &.is-not_selectable {
        color: #e4e7ea;
      }

      &:first-of-type {
        border-radius: 50% 0 0 50%;
      }

      &:last-of-type {
        border-radius: 0 50% 50% 0;
      }

      &.is-prev_month,
      &.is-next_month {
        color: rgba(172, 179, 187, 0.85);
      }

      &.is-selected {
        background: #e3effc;
        color: #404040;
        position: relative;
        z-index: 1;
        border-radius: 0;

        &.is-start_selection {
          border-radius: 50% 0 0 50%;
        }

        &.is-start_selection,
        &.is-end_selection {
          background: #e3effc;
          color: #fff;

          &::before {
            content: "";
          }
        }

        &::before {
          background-color: $color-primary-medium-blue;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
          border-radius: 50%;
          color: #fff;
        }
      }
    }
  }
}

.drag-drop-container {
  width: auto;
  height: fit-content;

  &.title {
    font-size: 1em;
    margin: 10px auto;
  }

  .item {
    height: fit-content;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}

@media only screen and (max-width: $phone) {
  input.nxt-text-input {
    width: 100%;
  }

  textarea {
    &.nxt-textarea-list {
      width: 100%;
    }
  }

  .calendar {
    padding: 10px;
    margin: 20px 0;
  }
}
