.App-logo {
  height: 4vmin;
  pointer-events: none;
}

.App-header {
  background-color: $color-primary-navy;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main-container {
    max-width: 1200px;
    margin: auto;
    width: 100%;
    font-size: calc(15px + 2vmin);
    color: $color-primary-white;
    padding: 0.4em;
    color: $color-primary-core-light-blue;

    h1 {
      position: relative;
      .logout {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 25%;
        right: 0;
        font-size: 0.6em;
        span {
          margin: auto 10px;
          font-size: 0.5em;
          color: $color-primary-white;
        }
        i {
          font-size: 1em;
          color: $color-primary-white;
          cursor: pointer;
        }
      }
    }
  }
}

.App-header img {
  margin-right: 0.5em;
  height: calc(30px + 2vmin);
}

.main-container {
  padding: 30px 0;
  max-width: 1200px;
  margin: auto;
}

.bg-grey {
  background-color: $color-secondary-100;
  padding: 2em;
  border-radius: 0.5rem;
}

.btns-container {
  display: flex;
  flex-direction: row;

  & a {
    margin-right: 50px;
  }
}

textarea {
  box-sizing: border-box;
  padding: 16px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;

  &.nxt-textarea {
    width: 100%;
    min-height: 80px;
    border: 0px;
    border-bottom: 1px solid $color-secondary-500;
    color: $color-secondary-500;
    background: $color-secondary-100;
  }

  &.nxt-textarea-list {
    width: 50%;
    min-height: 180px;
    border: 1px solid #666666;
    color: #92799a;
    background-color: #ffffff;
  }
}

.container {
  display: flex;
  flex-direction: row;
  padding-left: 0;
}

.new-campaign {
  .select-container {
    .select-item {
      width: 100%;
      margin-bottom: 0;

      label {
        display: inline-block;
        width: 33%;
        text-align: right;
        vertical-align: top;
        padding-top: 0.3rem;

        .hint {
          font-style: italic;
          font-size: 1rem;
          font-family: "BentonSansBBVA-Book", sans-serif;
          padding-top: 8px;
        }
      }

      .question,
      input,
      textarea,
      select {
        display: inline-block;
        max-width: 65%;
        width: 65%;
      }
    }
  }

  .dates-required {
    color: $color-tertiary-red;
  }
}

.create-campaign-by-type-button-list {
  display: inline-grid;
}

.input-container {
  &.fechas {
    label.error {
      color: $color-tertiary-red;
    }
    input {
      width: 10% !important;
      margin: 0 20px;
    }
  }

  &.texto {
    input {
      width: 40%;
    }
  }
}

.flexrow-container {
  display: flex;
  flex-direction: row;
}

.flexcolumn-container {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.home {
  .autocomplete {
    button {
      width: fit-content;
      align-self: flex-end;
    }
  }
  .input-container {
    input {
      width: 40vw;
    }
  }
}

.campaign-detail {
  .subtitle {
    color: $color-secondary-400;
  }

  .leaving-container {
    /*border-top: 1px solid #072146;*/
    background-color: $color-secondary-300;
  }

  .leaving-comments,
  .leaving-hrbp {
    font-size: 1.2rem;

    .title {
      font-size: 1.4rem;
    }
  }
}

.grid-campaigns-container {
  display: grid;
  grid-template-columns: 18% 35% 35% auto;

  .header {
    font-style: italic;
  }

  .cell {
    padding: 10px 0 10px 5px;

    &.even {
      background-color: $color-secondary-200;
    }

    &.odd {
      background-color: $color-secondary-300;
    }

    &.align-end {
      text-align: end;
      padding-left: 0;
      padding-right: 5px;
    }

    a {
      color: $color-primary-medium-blue;
    }

    .option-btn {
      text-decoration: none;
      cursor: pointer;

      &--red {
        color: $color-tertiary-red;
      }
    }

    .item-detail {
      font-size: 13px;
    }
  }
}

.filters-container {
  background-color: $color-secondary-200;
  padding: 1rem;
  border-radius: 0.5rem;
}

.menu-new-campaign {
  position: relative;

  .add-campaign {
    i {
      margin-right: 10px;
    }
  }

  .list {
    // position: absolute;
    width: fit-content;

    .list-item {
      /*width: fit-content;*/
      width: 100%;
      white-space: nowrap;
      padding: 8px;
      background-color: $color-secondary-200;

      a {
        text-decoration: none;
        color: $color-primary-white;
      }
    }

    .list-item .badge:hover {
      color: $color-primary-navy;
      border: 1px solid $color-primary-navy;
      background-color: white;
    }
  }
}

.working-on-it {
  justify-content: center;
  align-items: center;
  p {
    width: 50vw;
    font-size: 1.5em;
    span {
      display: inline-block;
      margin: 10px 0;
    }
  }
}

.tit-container,
.talent-magnet-container {
  .recruiting-recipient-container {
    background-color: $color-secondary-200;
    border-radius: 5px;
  }

  .btn-candidate-add,
  .btn-candidate-remove {
    color: $color-primary-white;
    padding: 8px 30px;
    border-radius: 0.5rem;
    i {
      margin: 0;
    }
  }

  .btn-candidate-add {
    background-color: $color-primary-medium-blue;
  }

  .btn-candidate-remove {
    background-color: $color-tertiary-red-dark;
  }
}

@media only screen and (max-width: $phone) {
  .new-campaign {
    .select-container {
      flex-direction: column;

      .select-item {
        label {
          width: 100%;
          margin-bottom: 10px;
          text-align: left;
        }

        .question,
        input,
        textarea,
        select {
          max-width: 100%;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
