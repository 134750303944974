.file-uploader {
  display: inline-block;

  #hiddenFileInput {
    display: none;
  }

  #uploadBtn {
    margin-left: 10px;
    font-size: 1.4rem;
    font-family: "BentonSansBBVA-Book", sans-serif;
    color: $color-secondary-500;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    box-sizing: border-box;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    cursor: pointer;
  
    &:hover {
      border-color: #888;
    }
  
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }
  }
}



