.hidden {
  display: none !important;
}

.link {
  /*color: $color-primary-aqua;*/
  /*font-family: $font-primary-bold;*/
  text-decoration: none;
  cursor: pointer;

  &__default {
    &:before {
      content: "<";
      margin-right: 5px;
    }
  }

  &:hover,
  &:focus {
    color: $color-primary-medium-light-blue;
  }

  &--bold {
    font-family: $font-primary-bold;
  }
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

.text-muted {
  color: $color-secondary-500 !important;
}
.text-error {
  color: $color-tertiary-red !important;
}
.text-success {
  color: $color-tertiary-green-dark !important;
}
.text-warning {
  color: $color-tertiary-orange !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: $base-size * 1px !important;
}
.mt-2 {
  margin-top: $base-size * 2px !important;
}
.mt-3 {
  margin-top: $base-size * 3px !important;
}
.mt-4 {
  margin-top: $base-size * 4px !important;
}
.mt-5 {
  margin-top: $base-size * 5px !important;
}
.mt-6 {
  margin-top: $base-size * 6px !important;
}
.mt-10 {
  margin-top: $base-size * 10px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: $base-size * 1px !important;
}
.mb-2 {
  margin-bottom: $base-size * 2px !important;
}
.mb-3 {
  margin-bottom: $base-size * 3px !important;
}
.mb-4 {
  margin-bottom: $base-size * 4px !important;
}
.mb-5 {
  margin-bottom: $base-size * 5px !important;
}
.mb-6 {
  margin-bottom: $base-size * 6px !important;
}
.mb-10 {
  margin-bottom: $base-size * 10px !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: $base-size * 1px !important;
}
.ml-2 {
  margin-left: $base-size * 2px !important;
}
.ml-3 {
  margin-left: $base-size * 3px !important;
}
.ml-4 {
  margin-left: $base-size * 4px !important;
}
.ml-5 {
  margin-left: $base-size * 5px !important;
}
.ml-10 {
  margin-left: $base-size * 10px !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: $base-size * 1px !important;
}
.mr-2 {
  margin-right: $base-size * 2px !important;
}
.mr-3 {
  margin-right: $base-size * 3px !important;
}
.mr-4 {
  margin-right: $base-size * 4px !important;
}
.mr-5 {
  margin-right: $base-size * 5px !important;
}
.mr-10 {
  margin-right: $base-size * 10px !important;
}

.m0-auto {
  margin: 0 auto !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: $base-size * 1px !important;
}
.pt-2 {
  padding-top: $base-size * 2px !important;
}
.pt-3 {
  padding-top: $base-size * 3px !important;
}
.pt-4 {
  padding-top: $base-size * 4px !important;
}
.pt-5 {
  padding-top: $base-size * 5px !important;
}
.pt-6 {
  padding-top: $base-size * 6px !important;
}
.pt-10 {
  padding-top: $base-size * 10px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: $base-size * 1px !important;
}
.pb-2 {
  padding-bottom: $base-size * 2px !important;
}
.pb-3 {
  padding-bottom: $base-size * 3px !important;
}
.pb-4 {
  padding-bottom: $base-size * 4px !important;
}
.pb-5 {
  padding-bottom: $base-size * 5px !important;
}
.pb-6 {
  padding-bottom: $base-size * 6px !important;
}
.pb-10 {
  padding-bottom: $base-size * 10px !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: $base-size * 1px !important;
}
.pl-2 {
  padding-left: $base-size * 2px !important;
}
.pl-3 {
  padding-left: $base-size * 3px !important;
}
.pl-4 {
  padding-left: $base-size * 4px !important;
}
.pl-5 {
  padding-left: $base-size * 5px !important;
}
.pl-10 {
  padding-left: $base-size * 10px !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: $base-size * 1px !important;
}
.pr-2 {
  padding-right: $base-size * 2px !important;
}
.pr-3 {
  padding-right: $base-size * 3px !important;
}
.pr-4 {
  padding-right: $base-size * 4px !important;
}
.pr-5 {
  padding-right: $base-size * 5px !important;
}
.pr-10 {
  padding-right: $base-size * 10px !important;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.text-small {
  font-size: 11px;
}

.text-bold {
  font-family: $font-primary-bold;
  font-weight: normal;
}

.text-regular {
  font-family: $font-primary-default;
  font-weight: normal;
}

.text-italic {
  font-family: $font-primary-default;
  font-weight: normal;
  font-style: italic;
}

.clear {
  clear: both;
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
