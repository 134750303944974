// RESET
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 11px;
}

body {
  #root {
    > div {
      box-sizing: border-box;
      color: $color-primary-navy; /*$color-secondary-500;*/
    }
  }
}

html, body {
  height: 100vh;
}

h1, h2, h3 {
  font-weight: inherit;
}

ul {
  list-style: none;
}

a, input, textarea {
  &:focus {outline:none;}
}

a {
  color: $color-secondary-500;
  &:focus {outline:none;}
  &::-moz-focus-inner {border:0;}
}

b {
  font-family: $font-primary-bold;
  font-weight: normal;
}

::selection {
  background-color: $color-primary-aqua;
  color: $color-primary-white;
}

h4 {
  font-size: 1.2em;
}

.text-center {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
  width: 100%;
  clear: both;
}

