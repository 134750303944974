$base-size: 8;

// PRIMARY COLORS
$color-primary-core-blue: #004481;
$color-primary-navy: #072146;
$color-primary-core-dark-blue: #043263;
$color-primary-core-light-blue: #1464A5;
$color-primary-medium-blue: #1973B8;
$color-primary-medium-light-blue: #49A5E6;
$color-primary-sky-blue: #5BBEFF;
$color-primary-sky-light-blue: #D4EDFC;
$color-primary-white: #FFFFFF;
$color-primary-aqua: #2DCCCD;
$color-primary-aqua-dark: #028484;
$color-primary-aqua-medium: #02A5A5;
$color-primary-aqua-light: #5AC4C4;
$color-primary-aqua-white: #EAF9FA;

// SECONDARY COLORS
$color-secondary-600: #121212;
$color-secondary-500: #666666;
$color-secondary-400: #BDBDBD;
$color-secondary-300: #D3D3D3;
$color-secondary-200: #E9E9E9;
$color-secondary-100: #F4F4F4;

// TERTIARY COLORS
$color-tertiary-red: #DA3851;
$color-tertiary-red-dark: #B92A45;
$color-tertiary-red-medium: #C0475E;
$color-tertiary-red-light: #E77D8E;
$color-tertiary-red-white: #F4C3CA;

$color-tertiary-yellow: #F8CD51;
$color-tertiary-yellow-dark: #9C6C01;
$color-tertiary-yellow-medium: #C49735;
$color-tertiary-yellow-light: #FADE8E;
$color-tertiary-yellow-white: #FEF5DC;

$color-tertiary-coral: #F35E61;
$color-tertiary-coral-dark: #CB353A;
$color-tertiary-coral-medium: #D44B50;
$color-tertiary-coral-light: #F59799;
$color-tertiary-coral-white: #FCDFDF;

$color-tertiary-pink: #F78BE8;
$color-tertiary-pink-dark: #AD53A1;
$color-tertiary-pink-medium: #C569B9;
$color-tertiary-pink-light: #FAB3F0;
$color-tertiary-pink-white: #FDDCF8;

$color-tertiary-orange: #F7893B;
$color-tertiary-orange-dark: #C65302;
$color-tertiary-orange-medium: #D8732C;
$color-tertiary-orange-light: #FAB27F;
$color-tertiary-orange-white: #FDE7D8;

$color-tertiary-sand: #D8BE75;
$color-tertiary-sand-dark: #8E7022;
$color-tertiary-sand-medium: #B79E5E;
$color-tertiary-sand-light: #E6D5A5;
$color-tertiary-sand-white: #F3EBD5;

$color-tertiary-green: #48AE64;
$color-tertiary-green-dark: #277A3E;
$color-tertiary-green-medium: #388D4F;
$color-tertiary-green-light: #88CA9A;
$color-tertiary-green-white: #D9EFE0;

$color-tertiary-purple: #8F7AE5;
$color-tertiary-purple-dark: #6754B8;
$color-tertiary-purple-medium: #7C6AC7;
$color-tertiary-purple-light: #B6A8EE;
$color-tertiary-purple-white: #DDD7F7;


// FONT
$font-primary-default: "BentonSansBBVA-Book", sans-serif;
$font-primary-light: "BentonSansBBVA-Light", sans-serif;
$font-primary-bold: "BentonSansBBVA-Medium", sans-serif;

$default-font-size: 1.4em;
$h1-font-size: 1.55em;
$h2-font-size: 1.5em;
$h3-font-size: 1.45em;


// GRID
$gutter-space: 10px;
$header-height: 56px;


// RESPONSIVE - MEDIA QUERIES
$phone: 767px;
$tab-port: 768px;
$desktop-s: 992px;
$desktop-m: 1200px;
$desktop-l: 1600px;

$template-width-min-1200: 90%;

