.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  border: 1px solid transparent;

  &.checked {
    background-color: $color-secondary-200;
    border: 1px solid $color-primary-navy;
    color: $color-primary-navy;
  }
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.badge-campaign-type {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* by campaign types */
.badge-desasignado_entrada {
  color: #fff;
  background-color: $color-tertiary-pink-light;
}

.badge-desasignado_salida {
  color: #fff;
  background-color: $color-tertiary-pink-medium;
}

.badge-status-filter-selector,
.badge-tit_vacante_solicitada {
  color: #fff;
  background-color: $color-primary-core-light-blue;
}

.badge-tit_vacante_conseguida {
  color: #fff;
  background-color: $color-primary-core-dark-blue;
}

.badge-zoom {
  color: #fff;
  background-color: $color-tertiary-orange-light;
}

.badge-recruiting {
  color: #fff;
  background-color: $color-tertiary-purple-light;
}

.badge-superado_periodo_prueba {
  color: #fff;
  background-color: $color-tertiary-green-light;
}

.badge-salida {
  color: #fff;
  background-color: $color-tertiary-red-light;
}

.badge-regular {
  color: #fff;
  background-color: $color-primary-aqua-light;
}
