// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

$nav-link-disabled-color:           $color-secondary-600 !default;
$nav-tabs-border-color:             $color-secondary-300 !default;
$nav-tabs-border-radius:            0.5 !default;
$nav-tabs-link-hover-border-color:  $color-secondary-200 $color-secondary-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $color-secondary-600 !default;
$nav-tabs-link-active-bg:           transparent !default;
$nav-tabs-link-active-border-color: $color-secondary-600 $color-secondary-600 $nav-tabs-link-active-bg !default;

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav-link {
    display: block;
    padding: 1rem;
  }
  
  .nav-tabs {
    .nav-item {
      cursor: pointer;
      user-select: none;
    }
  
    .nav-link {
      border: 1px solid transparent;
      color: $color-secondary-400;
  
      &.disabled {
        background-color: transparent;
      }
    }
  
    .nav-link.active,
    .nav-item.show .nav-link {
      color: $color-primary-navy;
      background-color: $color-secondary-100;
      border-radius: 10px 10px 0 0;
    }
  }
  
  .nav-fill {
    .nav-item {
      flex: 1 1 auto;
      text-align: center;
    }
  }
  
  .nav-justified {
    .nav-item {
      flex-basis: 0;
      flex-grow: 1;
      text-align: center;
    }
  }
  
  .tab-content {
    > .tab-pane {
      display: none;
    }

    > .active {
      display: block;
      background-color: $color-secondary-100;
    }
  }
  